import { useState, useCallback, useEffect, useRef, useContext } from "react";

import { Loader } from "@googlemaps/js-api-loader";
import debounce from "lodash.debounce";

import { GoogleApiKeyContext } from "~/utils/googlePlaceHook";
import { GoogleSessionTokenContext } from "~/utils/googleSessionToken";

export type GetPlaceDetails = (
  callback: (
    a: google.maps.places.PlaceResult | null,
    b: google.maps.places.PlacesServiceStatus,
  ) => void,
) => void;

export default function useAddressPredictions(input: string) {
  const [predictions, setPredictions] = useState<
    {
      description: string;
      getPlaceDetails: GetPlaceDetails;
    }[]
  >([]);
  const autocomplete = useRef<google.maps.places.AutocompleteService>();
  const placedetails = useRef<google.maps.places.PlacesService>();
  const apiKey = useContext(GoogleApiKeyContext);
  const { token, setToken } = useContext(GoogleSessionTokenContext);

  useEffect(() => {
    if (!autocomplete.current) {
      if (!apiKey) setPredictions([]);
      else
        new Loader({
          apiKey,
          libraries: ["places"],
        })
          .load()
          .then(({ maps }) => {
            autocomplete.current = new maps.places.AutocompleteService();
            placedetails.current = new maps.places.PlacesService(
              document.getElementById("predicted-places") as HTMLDivElement,
            );

            if (!token && setToken)
              setToken(new maps.places.AutocompleteSessionToken());
          });
    }
  }, [input]);

  const getPlacePredictions = (input: string, sessionToken: typeof token) => {
    autocomplete.current?.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: ["fr"] },
        sessionToken,
      },
      (predictions) => {
        setPredictions(
          predictions?.map((prediction) => {
            return {
              description: prediction.description,
              getPlaceDetails: (
                callback: (
                  a: google.maps.places.PlaceResult | null,
                  b: google.maps.places.PlacesServiceStatus,
                ) => void,
              ) =>
                placedetails.current?.getDetails(
                  {
                    placeId: prediction.place_id,
                    sessionToken,
                  },
                  callback,
                ),
            };
          }) || [],
        );
      },
    );
  };

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    [],
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input, token);
  }, [input, token]);

  return predictions;
}
